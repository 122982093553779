/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { BussinesProfileModel } from '../../core/_models'

type Props = {
  item: BussinesProfileModel
}

const BussinesInfoCell: FC<Props> = ({ item }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden'>
      
    </div>
    <div className='d-flex flex-column'>
      <div  className='text-gray-800 text-hover-primary mb-1'>
        {item.id}
      </div>
      {/* <span>Id: {item.id}</span> */}
    </div>
  </div>
)

export { BussinesInfoCell }
