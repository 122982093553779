import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { inviteRegister } from '../core/_requests'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { DatePicker, DatePickerProps } from 'antd'
import { initialUserValues, IUserModel } from '../core/_models'
import { sha256 } from 'crypto-hash'
import { AxiosError } from 'axios'
import { ErrorData, errorHandler } from '../../../../_metronic/helpers'
import EyeButton from './eye-button/EyeButton'


const registrationSchema = Yup.object().shape({
  acceptAdult: Yup.bool().required('Kötelező mező'),
  acceptTerms: Yup.bool().required('Kötelező mező'),
  first_name: Yup.string()
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  email: Yup.string()
    .email('Rossz formátum.')
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 karakter a követelmény.')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  password: Yup.string()
    .min(3, 'Minimum 3 karakter a követelmény.')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  changepassword: Yup.string()
    .required('Kötelező mező')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Nem egyezik a két mező értéke"),
    }),
  phone: Yup.string().required('Kötelező mező'),
  birth_date: Yup.string()
    .required('Kötelező mező')
})

export function InviteRegistration() {
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [passwordType, setPasswordType] = useState("password");

  const { token } = useParams();

  const navigate = useNavigate();

  const formik = useFormik<IUserModel>({
    initialValues: initialUserValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      try {
        const sha256Pw = await sha256(values.password);
        await inviteRegister(token ? token : "", values.email, sha256Pw, values.last_name, values.first_name, values.phone, values.birth_date)

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false)
          resetForm()
          navigate('/auth/successful-registration')
        }, 1000)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a módosítás, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorInfo);
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 2500)
        }
      } finally {
        setSubmitting(true)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    formik.setFieldValue('birth_date', dateString)
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='inviteRegistration_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Regisztráció</h1>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* end::Heading */}


      {/* 
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}



      {/* begin::Form group First_name */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Keresztnév</label>
        <input
          placeholder='Keresztnév'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('first_name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.errors.first_name,
            },
            {
              'is-valid': !formik.errors.first_name,
            }
          )}
        />
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-4'>
        {/* begin::Form group Last_name */}
        <label className='form-label fw-bolder text-dark fs-6 required'>Vezetéknév</label>
        <input
          placeholder='Vezetéknév'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('last_name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.errors.last_name,
            },
            {
              'is-valid': !formik.errors.last_name,
            }
          )}
        />
        {/* end::Form group */}
      </div>

      <div className='fv-row mb-4'>
        <div className='row mb-8'>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Telefon</label>
            <input
              placeholder='Telefon'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.phone && formik.errors.phone,
                },
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            />
          </div>
          <div className='col-lg-6'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Születési dátum</label>
            <DatePicker
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.errors.birth_date,
                },
                {
                  'is-valid': !formik.errors.birth_date,
                }
              )}
              onChange={onChange} />
          </div>
        </div>

      </div>


      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>E-mail</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1 position-relative'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Jelszó</label>
          <div className='mb-3'>
            <div className='position-relative'>
              <input
                type={passwordType}
                placeholder='Jelszó
              '
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>
            </div>

            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        {/* <div className='text-muted'>
        Használjon 8 vagy több karaktert betűk, számok és szimbólumok keverékével.
        </div> */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Jelszó újra</label>
        <input
          type='password'
          placeholder='Jelszó újra'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-check form-check-inline required' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            checked={formik.values.acceptTerms}
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            Elfogadom az{' '}
            <Link
              to='/dokumentumok/aszf'
              target='_blank'
              className='ms-1 link-primary'
            >
              Általános szerződési feltételeket
            </Link>
            {' '}és az{' '}<Link
              to='/dokumentumok/adatvedelmi-iranyelvek'
              target='_blank'
              className='ms-1 link-primary'
            >
              Adatvédelmi nyilatkozatot
            </Link>.
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-4'>
        <label className='form-check form-check-inline required' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            checked={formik.values.acceptAdult}
            {...formik.getFieldProps('acceptAdult')}
          />
          <span>
            Elmúltam 18 éves.
          </span>
        </label>
        {formik.touched.acceptAdult && formik.errors.acceptAdult && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptAdult}</span>
            </div>
          </div>
        )}
      </div>

      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-10 bg-light-success p-4 rounded mt-4'>
          <div className='text-success'>Sikeres regisztráció!</div>
        </div>
      )}
      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptAdult || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Tovább</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon..{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Bezár
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
