import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import { PurchasesModelQueryResponse } from './_models'

const API_URL_SBC = process.env.REACT_APP_SBC_API_DEV


const GET_PURCHASES = API_URL_SBC+"purchases"
export const PURCHASE_ID = `${API_URL_SBC}purchases/`
export const PURCHASE = `${API_URL_SBC}purchase/`


const getPurchase = (query: string): Promise<PurchasesModelQueryResponse> => {
  return axios
    .get(`${GET_PURCHASES}?${query}`)
    .then((d: AxiosResponse<PurchasesModelQueryResponse>) => d.data)
}

const getPurchaseById = (id: ID): Promise<any> => {
  return axios
    .get(`${PURCHASE_ID}${id}`)
    .then((response: AxiosResponse<PurchasesModelQueryResponse>) => response.data.data)
}

const getPurchaseInvoice = (id: ID): Promise<any> => {
  return axios
    .get(`${PURCHASE}${id}/invoice`,{responseType: 'blob'})
    .then((response: AxiosResponse<PurchasesModelQueryResponse>) => response)
}



export {getPurchase,getPurchaseById,getPurchaseInvoice}
