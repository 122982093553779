
import { CSSProperties, useEffect, useState } from 'react';
import { animated, useSpringRef, AnimatedProps, useTransition } from 'react-spring'
import { IUserModel } from '../core/_models';
import Registration1 from './RegistrationPages/RegistrationForm1'
import Registration2 from './RegistrationPages/RegistrationForm2'
import styles from './RegistrationPages/styles.module.css'


const initialValuesFilled = {
    city: '',
    state: '',
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    address: '',
    floor_door: '',
    zip: '',
    role: "",
    password: '',
    changepassword: '',
    acceptTerms: false,
    acceptAdult: false,
    privacyNotice: false,
    age: 0,
    gender: "",
    billing_tax_number: "",
    billing_name: '',
    billing_address: '',
    billing_floor_door: '',
    billing_zip: '',
    billing_city: '',
    birthday: "",
    category: "",
    birth_date: ""
}



export const RegistrationWrapper: React.FC = () => {
    const [index, set] = useState(0)
    const [prevIdx, setPrevIdx] = useState(-1)
    const [data, setData] = useState<IUserModel>(initialValuesFilled)

    const transRef = useSpringRef()

    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: () => ({ opacity: 0, transform: `translate(${prevIdx < index ? 100 : -100}%,0,0)` }),
        enter: () => ({ opacity: 1, transform: `translate3d(0%,0,0)` }),
        leave: () => ({ opacity: 0, transform: `translate3d(${prevIdx < index ? -50 : 50}%,0,0)` }),
        onRest: () => {
            setPrevIdx(index)
        }
    })
    useEffect(() => {
        transRef.start()
    }, [index])

    const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
        ({ style }) => <animated.div style={{ ...style }}><Registration1 data={data} setData={setData} setIndex={set} /></animated.div>,
        ({ style }) => <animated.div style={{ ...style }}><Registration2 data={data} setData={setData} setIndex={set} /></animated.div>,
    ]

    return (
                <div id="registration_form">
                    <div className={`flex animation-fill ${styles.container}`}>
                    {transitions((style, i) => {
                        const Page = pages[i]
                        return <Page style={style} />
                    })}
                    </div>
                 
                </div>
    );

}