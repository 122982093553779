export interface IUserModel {
  state: string
  changepassword: string
  acceptTerms: boolean,
  acceptAdult: boolean,
  email: string
  password: string
  first_name: string
  last_name: string
  phone: string
  address: string
  birth_date: string
  zip: string
  city: string
  floor_door: string
  gender: string,
  role: string
  billing_name: string,
  billing_zip: string,
  billing_city: string,
  billing_address: string,
  billing_floor_door: string,
  billing_tax_number: string,
  description?: string,
  isSameAddress?: boolean
}


export const initialUserValues: IUserModel = {
  state: "",
  changepassword: "",
  acceptTerms: false,
  acceptAdult: false,
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  phone: "",
  address: "",
  birth_date: "",
  zip: "",
  city: "",
  floor_door: "",
  gender: "",
  role: "",
  billing_name: "",
  billing_zip: "",
  billing_city: "",
  billing_address: "",
  billing_floor_door: "",
  billing_tax_number: "",
  description: ""
}
export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface AuthModelSbc {
  token: string
  data: any
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  first_name: string
  last_name: string
  role: string
  image_url: string
  id: string | number
  token: string

  // username: string
  // password: string | undefined
  // email: string
  // fullname?: string
  // occupation?: string
  // companyName?: string
  // phone?: string
  // roles?: Array<number>
  // pic?: string
  // language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  // timeZone?: string
  // website?: 'https://keenthemes.com'
  // emailSettings?: UserEmailSettingsModel
  // auth?: AuthModel
  // communication?: UserCommunicationModel
  // address?: UserAddressModel
  // socialNetworks?: UserSocialNetworksModel
}
