/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { selectUserRole, toAbsoluteUrl } from '../../../helpers'
import { Modal } from 'antd'
import { UserEditModalFormWrapper } from './user-modal/user-edit-modal/UserEditModalFormWrapper'
import { User } from './user-modal/core/_models'

type Props = {
  isLoading: boolean
  user: User
  error: unknown
}


const HeaderUserMenu: FC<Props>  = ({user,isLoading,error}) => {
  const { logoutSbc } = useAuth()
  const [orderModal, setOrderModal] = useState<boolean>(false)

  const showModal = () => {
    // setIsModalOpen(true);
    setOrderModal(!orderModal)
  };

  const handleOk = () => {
    // setIsModalOpen(false);
    setOrderModal(!orderModal)
  };

  const handleCancelShop = () => setOrderModal(false);


  const handleLogOut = () => {
   logoutSbc();
  }
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-4'>
            <img alt='Logo' src={user?.image_url ? user?.image_url : toAbsoluteUrl(`/media/sbc_assets/placeholder-image.png`)} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5 text-start'>
              {user?.last_name} {user?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-8 text-start'>
              {selectUserRole(user?.role)}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
  
      <div className='menu-item px-1 my-1'>
        <button onClick={showModal} className='btn menu-link menu-item-hover px-5'>
          Beállítások
        </button>

        <Modal className={"antd-modal-lg-w-1000"} title="Felhasználó" open={orderModal} onOk={handleOk} onCancel={handleCancelShop}
          footer={null}
        >
          {
            // <OrdersListWrapper handleOk={handleOk} />
            <UserEditModalFormWrapper user={user} isLoading={isLoading} error={error} cancel={handleCancelShop}/>
          }

        </Modal>
      </div>

      <div className='menu-item px-1'>
        <button onClick={handleLogOut} className='btn menu-link menu-item-hover px-5'>
          Kijelentkezés
        </button>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
