import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { isBusinessAdmin, isBusinessManager, isSbcAdmin, WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { DocsPage } from '../modules/docs/DocsPage'

const PrivateRoutes = () => {
  const { currentUserSbc } = useAuth()
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const UsersPage = lazy(() => import('../modules/users/UsersPage'))
  const CalendarPage = lazy(() => import('../modules/calendar/CalendarPage'))
  const BusinessProfilePage = lazy(() => import('../modules/bussinesProfiles/routing/BusinessProfilePage'))
  const ShopCategoryPage = lazy(() => import('../modules/shop-category/ShopCategoryPage'))
  const OrdersPage = lazy(() => import('../modules/orders/routing/OrdersPage'))



  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {currentUserSbc?.role != "administrator" ? (<Route path='auth/*' element={<Navigate to='/calendar' />} />) :
          (<Route path='auth/*' element={<Navigate to='/users' />} />)}
        {/* Pages */}
        {(isSbcAdmin(currentUserSbc?.role as string) || isBusinessAdmin(currentUserSbc?.role as string)) && <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />}
        {(isBusinessManager(currentUserSbc?.role as string) || isBusinessAdmin(currentUserSbc?.role as string)) && <Route
          path='calendar/*'
          element={
            <SuspensedView>
              <CalendarPage />
            </SuspensedView>
          }
        />}

        {(isSbcAdmin(currentUserSbc?.role as string) || isBusinessAdmin(currentUserSbc?.role as string)) && <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />}
        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        {(isSbcAdmin(currentUserSbc?.role as string)) && <Route
          path='/shop-category/*'
          element={
            <SuspensedView>
              <ShopCategoryPage />
            </SuspensedView>
          }
        />}

        {(isSbcAdmin(currentUserSbc?.role as string) || isBusinessAdmin(currentUserSbc?.role as string)) && <Route
          path='business/*'
          element={
            <SuspensedView>
              <BusinessProfilePage />
            </SuspensedView>
          }
        />}
        <Route path='dokumentumok/*' element={<DocsPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
