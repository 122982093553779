import moment from 'moment'
import { Dispatch, SetStateAction } from 'react'
import { ConnectedUsersResponseModel } from '../../../app/modules/bussinesProfiles/bussines-profile-list/components/subModals/users/components/core/_models'
import { BussinesProfileModel } from '../../../app/modules/bussinesProfiles/bussines-profile-list/core/_models'
import { ResponseCalendarModel } from '../../../app/modules/calendar/core/_models'
import { PurchasesModel } from '../../../app/modules/orders/orders-list/core/_models'
import { Product } from '../../../app/modules/products/products-list/core/_models'
import { User } from '../../../app/modules/users/users-list/core/_models'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  "per-page": 10 | 30 | 50 | 100
  links?: Array<{ label: string; is_active: boolean, active: boolean; url: string | null; page: number | null }>
}

export type CalendarState = {
  month?: string
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type StatusState = {
  status?: unknown
}

export type SubsStatus = {
  subscription_status?: unknown
}

export type DateFrom = {
  date_from?: unknown
}

export type DateTo = {
  date_to?: unknown
}

export type ProductType = {
  type?: unknown
}

export type BusinessProfile = {
  business_profile_id?: unknown
}

export type HasPrice = {
  has_price?: unknown
}

export type BusinessRole = {
  role?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & StatusState & SubsStatus & HasPrice & ProductType & BusinessProfile & BusinessRole & CalendarState & DateFrom & DateTo

export type CalendarQueryState = CalendarState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export type QueryRequestContextPropsCalendar = {
  state: CalendarQueryState
  updateState: (updates: Partial<CalendarQueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  "per-page": 10,
}

export const initialQueryStateForCalendar: CalendarQueryState = {
  month: moment().format("YYYY-MM"),
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export const initialQueryRequestCalendar: QueryRequestContextPropsCalendar = {
  state: initialQueryStateForCalendar,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseContextPropsNonArray<T> = {
  response?: Response<T> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export type ShopCategoriesListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: boolean | undefined
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Product
  setItemForUpdate: Dispatch<SetStateAction<Product | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type ProductListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: Product | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: Product
  setItemForUpdate: Dispatch<SetStateAction<Product | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type CalendarListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  isAllSelected: boolean


  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: ResponseCalendarModel | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: ID
  setItemForUpdate: Dispatch<SetStateAction<ID | undefined>>
  disabled: boolean
}

export type BusinessProfileListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: BussinesProfileModel | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: BussinesProfileModel
  setItemForUpdate: Dispatch<SetStateAction<BussinesProfileModel | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type PurchaseListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForUpdate?: PurchasesModel
  setItemForUpdate: Dispatch<SetStateAction<PurchasesModel | undefined>>
  productForView?: Product
  setProductForView: Dispatch<SetStateAction<Product | undefined>>
  userForView?: User
  setUserForView: Dispatch<SetStateAction<User | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type HeaderUserListViewContextProps = {
  itemForUpdate?: User
  setItemForUpdate: Dispatch<SetStateAction<User | undefined>>
}


export type UserListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  orderModal?: boolean
  setOrderModal: Dispatch<SetStateAction<boolean>>
  itemForAdd?: User | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  itemForUpdate?: User
  setItemForUpdate: Dispatch<SetStateAction<User | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export type ConnectedUserListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemForAdd?: ConnectedUsersResponseModel | boolean
  setItemForAdd: Dispatch<SetStateAction<boolean | undefined>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialConnectedUsersListView: ConnectedUserListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { }
}

export const initialBusinessListView: BusinessProfileListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { }
}

export const initialPurchaseListView: PurchaseListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  setProductForView: () => { },
  setUserForView: () => { },
  isAllSelected: false,
  disabled: false,
}


export const initialShopCategoriesListView: ShopCategoriesListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export const initialHeaderListView: HeaderUserListViewContextProps = {
  setItemForUpdate: () => { },
}


export const initialOrderListView: UserListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
  setOrderModal: () => { }
}

export const initialProductListView: ProductListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForAdd: () => { },
}

export const initialCalendarListView: CalendarListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  isAllSelected: false,
  disabled: false,
  setItemForUpdate: () => { },
  setItemForAdd: () => { },
}


export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
}

export interface PaginationProps {
  pageIndex: number
  pageOptions: number[]
  nextPage: () => void
  previousPage: () => void
  canNextPage: boolean,
  canPreviousPage: boolean
  setPageSize: (arg0: number) => void;
  gotoPage: (arg0: number) => void;
}

export type CustomQueryResponseContextProps<T> = {
  response?: Array<T> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}


export type ResponseProductCategoryItemModel = {
  id: string,
  name: string
}


export type optionItemForMultiSelect = {
  value: string,
  label: string
}

export type ListView = {
  id?: number;
  name?: string;
  code?: string
}

export type VatList = {
  id?: number;
  name?: string;
  vat_amount?: string;
  vat?: string;
}

export type ErrorData = {
  errorDetails: string
  errorInfo: string
  result: string
}

export type WindowWidthHeightModel = {
  width: number | undefined;
  height: number | undefined;
}

