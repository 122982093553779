import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
import { BusinessProfile, CalendarQueryState, CustomQueryResponseContextProps, ErrorData, ID, ListView, optionItemForMultiSelect, QueryResponseContextProps, QueryResponseContextPropsNonArray, QueryState, VatList, WindowWidthHeightModel } from './models'
import { useAuth } from '../../../app/modules/auth'
import { VatTypes } from './consts'
import moment from 'moment'
import { ResponseImgModel } from '../../../app/modules/bussinesProfiles/bussines-profile-list/core/_models'
import { Day } from '../../../app/modules/bussinesProfiles/bussines-profile-list/components/subModals/openingHours/core/_model'
import { getPurchaseInvoice } from '../../../app/modules/orders/orders-list/core/_requests'
import { AxiosError } from 'axios'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createResponseContextNonArray<T>(initialState: QueryResponseContextPropsNonArray<T>) {
  return createContext(initialState)
}

function createCustomResponseContext<T>(initialState: CustomQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, { filter: ['page', 'per-page'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''


  const status = state.status
    ? Object.entries(state.status as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const subscription_status = state.subscription_status
    ? Object.entries(state.subscription_status as Object)

      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const date_from = state.date_from
    ? Object.entries(state.date_from as Object)

      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const date_to = state.date_to
    ? Object.entries(state.date_to as Object)

      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const type = state.type
    ? Object.entries(state.type as Object)

      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const business_profile_id = state.business_profile_id
    ? Object.entries(state.business_profile_id as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const has_price = state.has_price
    ? Object.entries(state.has_price as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  const role = state.role
    ? Object.entries(state.role as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [pagination, sort, search, filter, status, subscription_status, type, business_profile_id, role, has_price, date_from, date_to]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function stringifyRequestQueryCalendar(state: CalendarQueryState): string {
  const pagination = qs.stringify(state, { filter: ['month'], skipNulls: true })

  return [pagination]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}


function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function makeRandomId(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

function errorHandler(code: number, info: string, details?: string) {
  var result = '';


  switch (code) {

    case 400:
      if (info == "ERROR_TIME_OFF_MISMATCH") {
        result = "Időpont ütközés."
      } else {
        result = "Hiányzó, vagy helytelenül megadott paraméterek."
      }
      break;
    case 401:
      result = "Autentikációs hiba."
      break;
    case 403:
      if (info == "ERROR_CONNECTION_ALREADY_EXISTS" || info == "ERROR_INVITE_ALREADY_EXISTS") {
        result = "A felhasználó korábban hozzá lett adva a profilhoz."
        break;
      }
      if (info == "ERROR_SUBSCRIPTION_REQUIRED") {
        result = "Az üzleti profilhoz nem társítható több felhasználó! Kiegészítő csomag vásárlásával bővíthető a felhasználók maximális száma."
        break;
      }
      else {
        result = "A kért művelethez nincs megfelelő jogosultsága."
      }
      break;
    case 409:
      if (info == "ERROR_EMAIL_ALREADY_TAKEN") {
        result = "Regisztrált e-mail cím, kérem adjon meg egy másikat."
      } else {
        result = "Nem megfelelő jogosultság."
      }
      break;
    case 500:
      if (details == "Invalid timeoff ID") {
        result = "Nem létező időpont."
      }
      else if (details == "Invalid date range") {
        result = "Nem megfelelő időpont, vagy időtartam."
      }
      else if (details == "Invalid user ID") {
        result = "A felhasználót, aki létrehozta a terméket törölték korábban."
      }
      else if (details == "Purchase has no invoice") {
        result = "Nem létezik a számla."
      }
      else if (info == "ERROR_BUSINESS_PROFILE_CATEGORY_FAILED") {
        result = "Kérlek várj, amíg az adminisztrátorok befejezik az üzleti profilod ellenőrzését."
      }
      else {
        result = "Adatbázis hiba, kérem próbálja újra később."
      }
      break;
    default:
      result = "Egyéb hiba."
      break;
  }
  return result;
}

function createMultiOptions(Array: any[]) {
  const result = Array.map(item => {
    return { value: item.id, label: item.name }
  })

  return result;
}

function MultiItemCategoriesConvertToString(selectedItems: optionItemForMultiSelect[]) {
  return selectedItems.map((item: any) => item.value)
}

function VatAmountSelector(selectedItemName: string) {

  return (Number((VatTypes.find(item => item.vat === selectedItemName) as VatList).vat_amount))
}

function BiggerThan5min(date: moment.Moment) {
  return (5 <= Number(date.format("m")) && 0 == Number(date.format("H"))) || (0 < Number(date.format("H")))
}

function getNetPrice(price: number, vat_amount: number) {
  if (vat_amount == 0) {
    return price
  }
  return Number(price / (1.0 + (vat_amount / 100)))
}

function cutPercent(name: string) {
  if (name == "5%" || name == "18%" || name == "27") {
    return name.slice(0, -1);
  }
  return name
}

function isSbcAdmin(name: string) {
  if (name == "administrator") {
    return true;
  }
  return false
}

function hasPrice(name: string) {
  if (name == "1") {
    return true;
  }
  return false
}

function getEventsType(name: string) {
  if (name == "free_time") {
    return " Nem foglalható ";
  }
  else if (name == "personal_booking") {
    return " Egyéni foglalás ";
  }
  else if (name == "group_booking") {
    return " Csoportos foglalás ";
  }
  else if (name == "reservation") {
    return " Aktív Foglalás ";
  }
}

function getPurchaseStatus(id: string) {
  if (id == "1") {
    return "badge-light-primary";
  }
  if (id == "2") {
    return "badge-light-success";
  }
  if (id == "3") {
    return "badge-light-info";
  }
  if (id == "4") {
    return "badge-light-warning";
  }
  if (id == "5") {
    return "badge-light-danger";
  }
  if (id == "6") {
    return "badge-light-secondary";
  }
}

function getProductType(name: string) {
  if (name == "product") {
    return "Termék";
  }
  else if (name == "subscription") {
    return "Előfizetés";
  }
  else if (name == "ticket") {
    return "Jegy";
  }

  else if (name == "event") {
    return "Időpont";
  }
}

function hasInvoice(id: string): boolean {
  if (id == "2") {
    return true;
  }
  return false;
}

function isTicket(name: string) {

  if (name == "ticket") {
    return true;
  }

  return false;
}

function isEvent(name: string) {
  if (name == "event") {
    return true;
  }

  return false;
}


function isBusinessAdmin(name: string) {
  if (name == "business_administrator") {
    return true;
  }
  return false
}

function isBusinessManager(name: string) {
  if (name == "business_manager") {
    return true;
  }
  return false
}

function isOwnProduct(productUserId: string, userID: string) {
  if (productUserId == userID) {
    return true;
  }
  return false
}

function isSmallScreenActive(width: number) {
  if (width >= 990) {
    return true;
  }

  return false;
}

function isTabletScreenActive(width: number) {
  if (width >= 480 && width < 990) {
    return true;
  }

  return false;
}

function isMobileScreenActive(width: number) {
  if (width <= 480) {
    return true;
  }

  return false;
}

function createEndData(dateStart: string, durationString: string) {
  const date2 = moment(dateStart, 'YYYY-MM-DD')
  let duration = moment(date2?.format('YYYY-MM-DD') + " " + durationString);
  const hour = duration.get('hour')
  const minute = duration.get('minute')
  const result = moment(dateStart).add(hour, 'hour').add(minute, 'minute');
  return moment(result).format('YYYY-MM-DD HH:mm:ss')
}


function differentTwoDatesInTime(dateStart: string, dateEnd: string) {
  var start = moment(dateStart, 'DD-MM-YYYY HH:mm:ss');
  var end = moment(dateEnd, 'DD-MM-YYYY HH:mm:ss');
  let diff = end.diff(start);
  return moment.utc(diff).format("HH:mm:ss");
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowWidthHeightModel>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function selectUserRole(role: string | undefined) {

  if (role == "administrator") {
    return "Sbc Admin"
  }
  if (role == "business_administrator") {
    return "B.Admin"
  }

  if (role == "business_manager") {
    return "B.Manager"
  }

  if (role == "buyer") {
    return "Vásárló"
  }
}

function selectStatusType(status: string) {
  if (status == "active") {
    return "Aktív"
  }
  if (status == "inactive") {
    return "Passzív"
  }
  if (status == "waiting") {
    return "Várakozó"
  }

}

function selectSubscriptionType(status: string) {

  if (status == "paid") {
    return "Előfizetve"
  }
  if (status == "waiting_for_payment") {
    return "Várakozó"
  }

}

function isReservation(type: string) {

  if (type == "reservation") {
    return true
  }
  return false;

}

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

//Convert response after fetch to images component
const convertImagesToAntdInput = (images: []) => {
  return images.map(
    (item: ResponseImgModel) => {
      return { uid: item.id, name: item.media_url?.substring(item?.media_url?.lastIndexOf("/") + 1, item?.media_url?.lastIndexOf(".")) as string, url: item.media_url }
    })
}

//Convert response after fetch to images component
const convertImageToAntdInput = (id: string, item: string) => {
  return [{ uid: id, name: item.substring(item?.lastIndexOf("/") + 1, item?.lastIndexOf(".")), url: item }]
}

//Day is not valid
const validDay = (day: Day): boolean => {
  const todayDateStr = new Date().toISOString().split('T').shift();

  const momentDateStart = moment(todayDateStr + ' ' + day.open_at).toDate();
  const momentDateEnd = moment(todayDateStr + ' ' + day.close_at).toDate();

  //If day is open and end Date not bigger than start date
  if (day.is_closed == 0) {
    return ((momentDateStart < momentDateEnd) && !(moment(momentDateStart).isSame(momentDateEnd)) ? true : false)
  } else {
    return true;
  }
}

//Get time for string such as "00:00:00"
const getTime = (date: any) => {
  return (date.hour() < 10 ? ("0" + date.hour()) : (date.hour())) + ":" + (date.minutes() < 10 ? ("0" + date.minutes()) : (date.minutes()))
}

//Download file
const downloadPdf = async (id: string) => {

  try {
    const resp = await getPurchaseInvoice(id);
    const blob = new Blob([resp.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(
      blob,
    );
  
    let filename = resp?.headers['content-disposition']?.split('filename=')[1]?.split('.')[0] || "invocie";
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    
    const err = error as AxiosError<ErrorData>
        if (err.response) {
          // const errorText = errorHandler(err.response.status, err.response.data.errorInfo,err.response.data.errorDetails);
          const errorText = "Nem elérhető a számla";
          return errorText;
        }
  }
 
}


//If Has stripe subscription return true
const hasSubscription = (item: string): boolean => {
  if (item == '0') {
    return false;
  } else {
    return true;
  }
}

//If Has active stripe account
const hasActiveStripeAccount = (item: string): boolean => {

  //If day is open and end Date not bigger than start date
  if (item == '0' || item == undefined) {
    return false;
  } else {
    return true;
  }
}

//If Has active stripe account
const isActiveButtons = (button1: number,button2: number): boolean => {

  if (button1 == button2 || button1 == button2+1 || button1 == button2+2) {
    return true;
  } else {
    return false;
  }
}

//If Has active stripe account
const isHasActiveOnlinePayment = (type: string): boolean => {
  if (type == "Online fizetés csomag") {
    return true;
  } else {
    return false;
  }
}

export {
  isHasActiveOnlinePayment,
  createMultiOptions,
  selectUserRole,
  createResponseContextNonArray,
  createEndData,
  BiggerThan5min,
  cutPercent,
  getNetPrice,
  makeRandomId,
  errorHandler,
  createResponseContext,
  stringifyRequestQuery,
  stringifyRequestQueryCalendar,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  createCustomResponseContext,
  MultiItemCategoriesConvertToString,
  VatAmountSelector,
  isSbcAdmin,
  isBusinessAdmin,
  isBusinessManager,
  getEventsType,
  differentTwoDatesInTime,
  useWindowSize,
  getProductType,
  getPurchaseStatus,
  isTicket,
  isEvent,
  isOwnProduct,
  selectStatusType,
  selectSubscriptionType,
  isSmallScreenActive,
  isTabletScreenActive,
  isMobileScreenActive,
  isReservation,
  getBase64,
  hasPrice,
  convertImagesToAntdInput,
  convertImageToAntdInput,
  validDay,
  getTime,
  hasInvoice,
  downloadPdf,
  hasSubscription,
  hasActiveStripeAccount,
  isActiveButtons
}
