import {Route, Routes} from 'react-router-dom'
import {RegistrationWrapper} from './components/RegistrationWrapper'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { SuccessfullyRegistration } from './components/SuccessfullReg'
import { SetForgotPassword } from './components/SetForgotPassword'
import { InviteRegistration } from './components/InviteRegistration'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<RegistrationWrapper />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='invite-registration/:token' element={<InviteRegistration />} />
      <Route path='forgot-password/:token' element={<SetForgotPassword />} />
      <Route path='successful-registration' element={<SuccessfullyRegistration/>} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
