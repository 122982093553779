/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { selectStatusType } from '../../../../../../../helpers'

type Props = {
  status: string
}


const BusinessStatusCell: FC<Props> = ({ status }) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span className={`badge ${(status == "active") && ' badge-light-success'} ${(status == "inactive") && ' badge-light-info' } ${(status == "waiting") && ' badge-light-warning'}`}>{selectStatusType(status)}</span>
    </div>
  </div>
)
  }
export { BusinessStatusCell }
