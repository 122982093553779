import {BussinesListToolbar} from './BussinesListToolbar'
import {BussinesListSearchComponent} from './BussinesListSearchComponent'

const BussinesListHeader = () => {
  return (
    <div className='card-header border-0 p-0 p-lg-8'>
      <BussinesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<BussinesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BussinesListHeader}
