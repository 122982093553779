// import { ListViewProvider, useListView } from '../bussines-profile-list/core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { BussinesListHeader } from '../components/header/BussinesListHeader'
import { BussinesTable } from '../table/BussinesTable'
import { KTCard } from '../../../../../../helpers'
// import { BussinesEditModal } from '../bussines-profile-list/bussines-edit-modal/BussinesEditModal'

const BussinesProfiles = () => {
  // const { itemForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <BussinesListHeader />
        <BussinesTable />
      </KTCard>
      {/* {(itemForUpdate !== undefined ) && <BussinesEditModal />} */}
    </>
  )
}

const BussinesProfileListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
        <BussinesProfiles />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { BussinesProfileListWrapper }
