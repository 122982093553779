import { Route, Routes } from "react-router-dom"
import { DocsLayout } from "./DocsLayout"
import { PrivacyPolicy } from "./components/Adatvedelmi"
import { TermsCondition } from "./components/Aszf"

const DocsPage = () => (
  <Routes>
    <Route element={<DocsLayout />}>
      <Route path='adatvedelmi-iranyelvek' element={<PrivacyPolicy />} />
      <Route path='aszf' element={<TermsCondition />} />
      <Route index element={<PrivacyPolicy />} />
    </Route>
  </Routes>
)

export {DocsPage}