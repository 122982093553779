import { FC } from 'react'
import { selectSubscriptionType } from '../../../../../../../helpers'

type Props = {
  status: string
}

const BusinessSubscription: FC<Props> = ({ status }) => {

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span className={`badge ${(status == "paid") && ' badge-light-success'} ${(status == "waiting_for_payment") && ' badge-light-info'} `}>{selectSubscriptionType(status)}</span>
      </div>
    </div>
  )
}

export { BusinessSubscription }
