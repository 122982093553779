import { User } from '../core/_models'
import { UserSbcEditModalForm } from './content/UserSbcEditModalForm'
import { UserBussinesEditModalForm } from './content/UserBussinesEditModalForm'
import { FC } from 'react'

type Props = {
  isLoading: boolean
  user: User
  error: unknown
  cancel: () => void
}

const UserEditModalFormWrapper: FC<Props> = ({ user, isLoading, error, cancel }) => {


  const selectType = (user: User, isUserLoading: boolean) => {
    if (user.role == "administrator") {
      return <UserSbcEditModalForm isUserLoading={isUserLoading} User={user} cancel={cancel} />
    }

    if (user.role != "buyer") {
      return <UserBussinesEditModalForm isUserLoading={isUserLoading} User={user} cancel={cancel} />
    }
  }



  return (
    <>
      {!user && <div>Betöltés..</div>}
      {!isLoading && !error && user && selectType(user, isLoading)}

    </>

  )
  // if (!itemForUpdate) {
  //   return <div>Loading...</div>
  // }

  // if (!isLoading && !error && user) {
  // return selectType(user,isLoading)
  // }


}

export { UserEditModalFormWrapper }
