/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, FC } from 'react'
import { useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { registerForSbc } from '../../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorData, errorHandler, isMobileScreenActive, isSmallScreenActive, isTabletScreenActive, RoleData, useWindowSize } from '../../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../../_metronic/assets/ts/components'
import { IUserModel } from '../../core/_models'
import { a, useTrail } from 'react-spring'
import { sha256 } from 'crypto-hash';
import { AxiosError } from 'axios'

const registrationSchema = Yup.object().shape({
  isSameAddress: Yup.boolean(),
  billing_name: Yup.string().required('Kötelező mező'),
  billing_tax_number: Yup.string().required('Kötelező mező'),
  billing_city: Yup.string().required('Kötelező mező'),
  billing_zip: Yup.string().required('Kötelező mező'),
  billing_address: Yup.string().required('Kötelező mező'),
  role: Yup.string().required('Kötelező mező'),
  city: Yup.string().when("isSameAddress", {
    is: true,
    then: Yup.string().required("Kötelező mező")
  }),
  zip: Yup.string().when("isSameAddress", {
    is: true,
    then: Yup.string().required("Kötelező mező")
  }),
  address: Yup.string().when("isSameAddress", {
    is: true,
    then: Yup.string().required("Kötelező mező")
  })
  // description: Yup.string().required('Kötelező mező').min(5, 'Minimum 5 karakter a követelmény'),
})

const config2 = { mass: 5, tension: 10, friction: 20 };


type Props = {
  data: IUserModel
  setData: React.Dispatch<React.SetStateAction<IUserModel>>
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const Registration2: FC<Props> = ({ data, setData, setIndex }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isNewAddress, setNewAddress] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const clickFunction = () => {
    setNewAddress(!isNewAddress)
    formik.setFieldValue("isSameAddress", !formik.values.isSameAddress)
  }

  const navigate = useNavigate();
  const formik = useFormik<IUserModel>({
    initialValues: data,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      try {
     

        const sha256Pw = await sha256(values.password);
        const { data: auth } = await registerForSbc(
          values.email,
          values.first_name,
          values.last_name,
          sha256Pw,
          values.phone,
          values.gender,
          values.birth_date,
          isNewAddress ? values.zip : values.billing_zip,
          isNewAddress ? values.city : values.billing_city,
          isNewAddress ? values.address : values.billing_address,
          isNewAddress ? values.floor_door : values.billing_floor_door,
          values.billing_name,
          values.billing_zip,
          values.billing_city,
          values.billing_address,
          values.billing_floor_door,
          values.billing_tax_number,
          values.role
        )

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false)
          resetForm()
          navigate('/auth/successful-registration')
        }, 2500)

      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a módosítás, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorInfo);
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 2500)
        }
      } finally {
        setSubmitting(true)
        setLoading(false)
      }

    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const form = [
    <>
      <div className='fv-row mb-6'>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2 required'>Város</label>
            <input
              type='text'
              className={clsx(
                'form-control form-control-lg bg-transparent mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched.city && formik.errors.city,
                },
                {
                  'is-valid': formik.touched.city && !formik.errors.city,
                }
              )}
              placeholder='Város'
              {...formik.getFieldProps('city')}
            />
    
          </div>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2 required'>Ir.szám</label>
            <input
              type='text'
              className={clsx(
                'form-control form-control-lg bg-transparent',
                {
                  'is-invalid': formik.touched.zip && formik.errors.zip,
                },
                {
                  'is-valid': formik.touched.zip && !formik.errors.zip,
                }
              )}
              placeholder='1234'
              {...formik.getFieldProps('zip')}
            />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2 required'>Utca / Házszám</label>
            <input
              type='text'
              className={clsx(
                'form-control form-control-lg bg-transparent mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched.address && formik.errors.address,
                },
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              placeholder='Baross utca 2'
              {...formik.getFieldProps('address')}
            />
          </div>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2'>Emelet / Ajtó</label>
            <input
              type='text'
              className='form-control form-control-lg bg-transparent'
              placeholder='4/11'
              {...formik.getFieldProps('floor_door')}
            />
          </div>
        </div>
      </div>
    </>]
const size = useWindowSize()

  const trail = useTrail(form.length, {
    config2,
    opacity: isNewAddress ? 1 : 0,
    x: isNewAddress ? 0 : 20,
    height: isNewAddress ? (isSmallScreenActive(size.width as number) ? 160 : 300) : 0,
    from: { opacity: 0, x: 20, height: 0 }
  });

  return (
    <form
      className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${isNewAddress && (isMobileScreenActive(size.width as number) || isTabletScreenActive(size.width as number)) && ' mt-300'}`}
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Céges Adatok</h1>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* end::Heading */}


      {/* 
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group phone */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Cég neve</label>
        <input
          placeholder='Cég neve'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('billing_name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.billing_name && formik.errors.billing_name,
            },
            {
              'is-valid': formik.touched.billing_name && !formik.errors.billing_name,
            }
          )}
        />
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group vatNumber */}
        <label className='form-label fw-bolder text-dark fs-6 required'>Adószám</label>
        <input
          placeholder='Adószám'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('billing_tax_number')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.billing_tax_number && formik.errors.billing_tax_number,
            },
            {
              'is-valid': formik.touched.billing_tax_number && !formik.errors.billing_tax_number,
            }
          )}
        />

        {/* end::Form group */}
      </div>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Jogosultság</label>
        <div className='col-lg-8 fv-row'>
          <select
            className='form-select bg-transparent form-select-lg'
            {...formik.getFieldProps('role')}
          >
            <option value={-1}>Válassza ki a munkakörét...</option>
            {RoleData && RoleData.map((item: any) => {
              return (<option value={item.code} id={item.code} key={item.name + "-" + item.code}>{item.name}</option>)
            })}
          </select>
          {formik.touched.role && formik.errors.role && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.role}</div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-6'>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2 required'>Város</label>
            <input
              type='text'
              autoComplete='off'
              className={clsx(
                'form-control form-control-lg bg-transparent mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched.billing_city && formik.errors.billing_city,
                },
                {
                  'is-valid': formik.touched.billing_city && !formik.errors.billing_city,
                }
              )}
              placeholder='Város'
              {...formik.getFieldProps('billing_city')}
            />
          </div>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2 required'>Ir.szám</label>
            <input
              type='text'
              className={clsx(
                'form-control form-control-lg bg-transparent',
                {
                  'is-invalid': formik.touched.billing_zip && formik.errors.billing_zip,
                },
                {
                  'is-valid': formik.touched.billing_zip && !formik.errors.billing_zip,
                }
              )}
              placeholder='1234'
              {...formik.getFieldProps('billing_zip')}
            />
          </div>
        </div>
      </div>

      <div className='fv-row mb-6'>
        <div className='row'>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2 required'>Utca / Házszám</label>
            <input
              type='text'
              className={clsx(
                'form-control form-control-lg bg-transparent mb-3 mb-lg-0',
                {
                  'is-invalid': formik.touched.billing_address && formik.errors.billing_address,
                },
                {
                  'is-valid': formik.touched.billing_address && !formik.errors.billing_address,
                }
              )}
              placeholder='Utca / Házszám'
              {...formik.getFieldProps('billing_address')}
            />

          </div>
          <div className='col-lg-6'>
            <label className='fw-bold fs-6 mb-2'>Emelet / Ajtó</label>
            <input
              type='text'
              className='form-control form-control-lg bg-transparent'
              placeholder='3/9'
              {...formik.getFieldProps('billing_floor_door')}
            />

          </div>
        </div>
      </div>


      {/* SEPARATOR */}
      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <span>
            Más az üzlet címe
          </span>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            checked={isNewAddress}
            onClick={clickFunction}
          />
        </label>
      </div>

      {isNewAddress && trail.map(({ x, height, ...rest }, index) => (
        <a.div
          key={x + "-" + index}
          className="trails-text"
          style={{
            ...rest
                    }}
        >
          <a.div style={{ height }}>{form[index]}</a.div>
        </a.div>
      ))}



      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-10 bg-light-success p-4 rounded mt-4'>
          <div className='text-success'>Sikeres regisztráció!</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='text-center mt-8'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Elfogad</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          onClick={() => setIndex(0)}
        >
          Vissza
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Bezár
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
export default Registration2;
