import axios, { AxiosResponse } from 'axios'
import { ID} from '../../../../../helpers'
import { User, UsersQueryResponse } from './_models'

const API_URL_SBC_ADMIN = process.env.REACT_APP_SBC_API_DEV



// export const REGISTRATION_URL_SBC_USER = `${API_URL_SBC_USER}registration`
export const URL_SBC_ADMIN = `${API_URL_SBC_ADMIN}user`

const getUserById = (id: ID): Promise<any> => {
  return axios
    .get(`${URL_SBC_ADMIN}/${id}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => response.data.data)
}

const putUser = (user: User, user_id: ID) => {
  return axios.put<User>(`${URL_SBC_ADMIN}/${user_id}`, {
    first_name: user.first_name,
    last_name: user.last_name,
    phone: user.phone,
    email: user.email,
    status: user.status,
    image: user.image_url,
    role: user.role,
    gender:user.gender,
    image_deleted:user.image_deleted
  })
}



export { getUserById, putUser }

