// @ts-nocheck
import { Column } from 'react-table'
import { BussinesInfoCell } from './BussinesInfoCell'
import { ProductCustomHeader } from './BusinessProfileCustomHeader'
import { Product } from '../../core/_models'
import {BusinessSubscription} from './BusinessSubscription'
import {BusinessStatusCell} from './BusinessStatusCell'
const usersColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='Id' className='min-w-125px' />,
    id: 'id',
    Cell: ({ ...props }) => <BussinesInfoCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='Név' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='E-mail' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => <ProductCustomHeader tableProps={props} title='Adószám' className='min-w-125px' />,
    accessor: 'tax_number',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Előfizetés' className='min-w-125px' />
    ),
    id: 'subscription_status',
    Cell: ({ ...props }) => <BusinessSubscription status={props.data[props.row.index].subscription_status} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Státusz' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({ ...props }) => <BusinessStatusCell status={props.data[props.row.index].status} />,
  },

  // {
  //   Header: (props) => (
  //     <ProductCustomHeader tableProps={props} title='Státusz' className='min-w-125px' />
  //   ),
  //   acessor: 'status',
  // }
]

export { usersColumns }
