import axios from 'axios'
import { AuthModel, AuthModelSbc, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_SBC = process.env.REACT_APP_SBC_API_DEV

export const REGISTRATION_URL_SBC = `${API_URL_SBC}user/registration`

export const LOGIN_URL_SBC = `${API_URL_SBC}user/login`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL_SBC}user/password-reset`
export const SET_PASSWORD_URL = `${API_URL_SBC}user/password-set`

export const INVITE_REGISTER = `${API_URL_SBC}user/invite-registration`


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function loginForSbc(email: string, password: string) {
  return axios.post<AuthModelSbc>(LOGIN_URL_SBC, {
    email,
    password,
  })
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return AuthModel
export function registerForSbc(
  email: string,
  first_name: string,
  last_name: string,
  password: string,
  phone: string,
  gender: string,
  birth_date: string,
  zip: string,
  city: string,
  address: string,
  floor_door: string,
  billing_name: string,
  billing_zip: string,
  billing_city: string,
  billing_address: string,
  billing_floor_door: string,
  billing_tax_number: string,
  role: string
) {
  return axios.post(REGISTRATION_URL_SBC, {
    email,
    first_name,
    last_name,
    password,
    phone,
    gender,
    birth_date,
    zip,
    city,
    address,
    floor_door,
    billing_name,
    billing_zip,
    billing_city,
    billing_address,
    billing_floor_door,
    billing_tax_number,
    role,
    status:"inactive"
  }
  )
}

export function inviteRegister(
  token: string,
  email: string,
  password: string,
  last_name: string,
  first_name: string,
  phone: string,
  birth_date: string
) {
  return axios.post(INVITE_REGISTER, {
    invite_key: token,
    email: email,
    password: password,
    last_name: last_name,
    first_name: first_name,
    phone: phone,
    birth_date: birth_date
  }
  )
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function setPassword(token:string,password: string) {
  return axios.patch<{ result: boolean }>(SET_PASSWORD_URL, {
  password_reset_token: token,
  password: password
  })
}
