import {BussinesListFilter} from './BussinesListFilter'

const BussinesListToolbar = () => {

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <BussinesListFilter />
    </div>
  )
}

export {BussinesListToolbar}
