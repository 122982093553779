import axios, {AxiosResponse} from 'axios'
import { ID } from '../../../../../../helpers'
import { BussinesProfileQueryResponse} from './_models'

const API_URL_SBC = process.env.REACT_APP_SBC_API_DEV
const GET_BUSSINES_PROFILES = API_URL_SBC+"user"


const getBusinessProfile = (query: string,id:ID): Promise<BussinesProfileQueryResponse> => {
  return axios
    .get(`${GET_BUSSINES_PROFILES}/${id}/business-profiles?${query}`)
    .then((d: AxiosResponse<BussinesProfileQueryResponse>) => d.data)
}


export {getBusinessProfile}
