import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import {  AuthModelSbc, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'

type AuthContextProps = {
  authSbc: AuthModelSbc | undefined
  saveAuthSbc: (auth: AuthModelSbc | undefined) => void
  currentUserSbc: UserModel | undefined
  setCurrentUserSbc: Dispatch<SetStateAction<UserModel | undefined>>
  logoutSbc: () => void
}

const initAuthContextPropsState = {
  authSbc: authHelper.getAuthSbc(),
  saveAuthSbc: () => { },
  currentUserSbc: undefined,
  setCurrentUserSbc: () => { },
  logoutSbc: () => { }
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const[authSbc, setAuthSbc] = useState<AuthModelSbc | undefined>(authHelper.getAuthSbc())
  const [currentUserSbc, setCurrentUserSbc] = useState<UserModel | undefined>()

  const saveAuthSbc = (authSbc: AuthModelSbc | undefined) => {
    setAuthSbc(authSbc)
    if (authSbc) {
      authHelper.setAuthSbc(authSbc.data)
    } else {
      authHelper.removeAuthSbc()
    }
  }

  // const saveAuth = (auth: AuthModel | undefined) => {
  //   setAuth(auth)
  //   if (auth) {
  //     authHelper.setAuth(auth)
  //   } else {
  //     authHelper.removeAuth()
  //   }
  // }

  // const logout = () => {
  //   saveAuth(undefined)
  //   setCurrentUser(undefined)
  // }

  const logoutSbc = () => {
    saveAuthSbc(undefined)
    setCurrentUserSbc(undefined)
  }
  return (
    <AuthContext.Provider value={{ authSbc,  saveAuthSbc,  currentUserSbc, setCurrentUserSbc, logoutSbc }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { authSbc,  setCurrentUserSbc, logoutSbc } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (user: AuthModelSbc) => {
      try {
        if (!didRequest.current) {
     

          setCurrentUserSbc(user as any)

        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logoutSbc()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (authSbc && authSbc.token) {
      requestUser(authSbc)
    } else {
      logoutSbc()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])



  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
