import { AuthModelSbc } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const SBC_LOCAL_STORAGE_KEY = 'sbc-auth-react-v'

const getAuthSbc = (): AuthModelSbc | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(SBC_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModelSbc = JSON.parse(lsValue) as AuthModelSbc
    if (auth) {
      // You can easily check auth_token expiration also


      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

// const setAuth = (auth: AuthModel) => {
//   if (!localStorage) {
//     return
//   }

//   try {
//     const lsValue = JSON.stringify(auth)
//     localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
//   } catch (error) {
//     console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
//   }
// }

const setAuthSbc = (auth: AuthModelSbc) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(SBC_LOCAL_STORAGE_KEY, lsValue)

  

  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

// const removeAuth = () => {
//   if (!localStorage) {
//     return
//   }

//   try {
//     localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
//   } catch (error) {
//     console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
//   }
// }

const removeAuthSbc = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(SBC_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


export function initAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json';


  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      config.headers.Authorization = `Basic ${process.env.REACT_APP_SBC_AUTH_TOKEN}`
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.request.use(
    (config: any) => {
      const authSbc = getAuthSbc()
      config.headers['User-Auth'] = `${authSbc?.token}`;
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

}



export { getAuthSbc, setAuthSbc, removeAuthSbc, AUTH_LOCAL_STORAGE_KEY, SBC_LOCAL_STORAGE_KEY }
